@font-face {
    font-family: Montserrat-regular;
    src: url("../Fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: Montserrat-semibold;
    src: url("../Fonts/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: Acumin_Variable;
    src: url("../Fonts/AcuminVariableConcept.otf");
}



html,
body {
    scroll-behavior: smooth;
    font-family: Acumin_Variable !important;
    overflow-x: hidden;
}

.img_last_banner {
    width: 100%;
}

.img_last_banner img {
    width: 100%;
    object-fit: cover;
}

.btn_achore {
    font-size: 18px;
    font-family: Acumin_Variable !important;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none;
    color: black;
}

.logo_class {
    width: auto;
    height: 53px;
}

.logo_class img {
    width: auto;
    height: inherit;
    object-fit: contain;
}

.phon_img img {
    width: 100%;
}

.phon_img {
    width: 40px;
    height: 40px;
}

.phone_class {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 25px;
}

.phone_class h6 {
    font-family: Acumin_Variable !important;
    font-size: 25px;
    color: #003994;
    font-weight: 600;
}

.header_class .appBar {
    background-color: white;
    /* flex-direction: row; */
}

.logo_container .MuiBox-root {
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 15px 50px;
    align-items: center;
}

.new_logo {
    width: auto;
    height: 53px;
}

.new_logo img {
    width: 100%;
    height: inherit;
    object-fit: contain;
}

.container_phon h6 a {
    text-decoration: none;
    color: inherit;
}

.container_phon {
    cursor: pointer;
    z-index: 99;
    position: fixed;
    padding: 10px 20px;
    border-radius: 50px;
    bottom: 20px;
    right: -130px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #F6C800;
    box-shadow: 0 0 50px gray;
    transition: all 0.8s ease-in-out;
}

.container_phon:hover {
    right: 10px;
}

.footer_class {
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    padding: 20px;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
}

.logo1 img,
.logo2 img,
.logo3 img {
    width: 100%;
    height: 80px;
    object-fit: contain;

}

.logo1 {
    width: 30%;
    /* height: 80px; */
    background-color: white;
    padding: 5px;
}

.logo2 {
    width: 30%;
    background-color: white;
    padding: 5px;
}

.logo3 {
    width: 30%;
    background-color: white;
    padding: 5px;
}

.fist_cont {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.sec_cont h4 {
    font-family: Acumin_Variable !important;
    font-size: 28px;
    margin: 0;
}

.fist_cont,
.sec_cont {
    width: 47%;
}

.bann_img_cls {
    width: 100%;
    position: relative;
}

.bann_img_cls .banner_img {
    display: block;
    width: 100%;
    height: 650px;
    object-fit: cover;
    transform: scaleX(-1);
    padding: 0 0 50px 0;
}

.bann_img_cls .text_ban_cls {
    position: absolute;
    top: 33%;
    left: 8%;
    color: white;
    font-family: Acumin_Variable !important;
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;

}

.form_div {
    width: 300px;
    background-color: white;
    border: 2px solid transparent;
    border-radius: 20px;
    position: absolute;
    right: 5%;
    top: 14%;
    padding: 20px 25px 40px 25px;
}

.form_ti {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-family: Acumin_Variable !important;
    font-size: 27px !important;
}

.form_text_con {
    display: flex;
    align-items: flex-end;
    margin: 25px 0;
    border-bottom: 1px solid;
}

.form_text_con h6 {
    border-bottom: 1px solid transparent;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    font-family: Acumin_Variable !important;
}

.form_text_con .text_field_form input {
    color: #7455ac;
    padding: 0px 15px;
    font-family: Acumin_Variable !important;
    font-size: 13px;
}

.form_text_con .text_field_form {
    min-width: 60% !important;
    border-bottom: 1px solid transparent;
}

.form_text_con .text_field_form div::before,
.form_text_con .text_field_form div::after {
    border: 0 !important;
}

.form_btn {
    position: absolute !important;
    bottom: -20px;
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto !important;
    background-color: #003994 !important;
    font-weight: 600 !important;
}

.social_media_container {
    background-color: #003994;
    border: 1px solid transparent;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 30%;
    left: 0;
    padding: 10px;
}

.social_img_div {
    margin: 20px 0;
    width: 25px;
    height: 25px;
}

.social_img_div img {
    width: 100%;
    height: 25px;
    object-fit: contain;
}

.tit_continer {
    text-align: center;
    margin: 0 auto;
}

.title_of_section {
    font-family: Acumin_Variable !important;
    text-align: center;
    font-size: 35px !important;
    font-weight: 600 !important;
    letter-spacing: 2px !important;
    color: white;
    background-image: linear-gradient(to right, #8b5aa4, #a63b92, #8b5aa4);
    width: fit-content !important;
    padding: 12px 50px;
    /* border: 1px solid transparent; */
    border-radius: 60px;
    margin: 40px auto !important;
}

.academic_img_cont {
    position: relative;
    width: 24%;
}

.academic_img_titl {
    position: absolute;
    /* padding: 10px 15px; */
    bottom: -15px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.academic_img_titl h3 {
    font-family: Acumin_Variable !important;
    font-size: 25px !important;
    color: white;
    background-color: #8b5aa4;
    width: 80%;
    margin: 0 auto;
    padding: 10px 10px;
}

.academ_img {
    height: 200px;
}

.academ_img img {
    height: inherit;
    width: 100%;
    object-fit: cover;
}

.btn_academic_container {
    max-width: 1200px;
    margin: 20px auto 60px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

}

.btn_academic_container button:hover {
    background-color: white;
    color: black;
    box-shadow: 0px 0px 15px lightgrey;
}

.btn_academic_container button {
    box-shadow: 0px 0px 25px lightgrey;
    border-radius: 10px;
    padding: 20px 10px !important;
    text-transform: none;
    background-color: white;
    color: black;
    font-size: 30px;
    line-height: 45px;
    font-family: Acumin_Variable !important;
    width: 14%;
}

.aca_main_container {
    padding: 50px 20px 70px 20px;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.academ_img img {
    width: 100%;
}

.academic_main_div {
    position: relative;
    padding: 0 0 20px 0;
}

.about_main_div {
    max-width: 1300px;
    margin: 0 auto;
    padding: 50px 0 30px 0;
}

.banner_about_container {
    width: 100%;
    height: 500px;
}

.banner_about_container img {
    width: 100%;
    height: inherit;
    object-position: 0px -50px;
    object-fit: cover;
}

.about_paragraph h4 {
    font-family: Acumin_Variable;
    font-weight: 400;
    font-size: 30px;
    margin: 20px 0;
    text-align: center;
}

.about_paragraph {
    padding: 0 60px;
}

.talk_to_us_container {
    text-align: center;
}

.talk_to_us_container .talk_btn:hover {
    background-color: #F6C800;
    color: black;
    box-shadow: 0px 0px 10px gray;
}

.talk_to_us_container .talk_btn {
    font-family: Acumin_Variable !important;
    text-align: center;
    font-size: 25px !important;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
    color: black;
    box-shadow: 0px 25px 50px gray;
    /* background-image:linear-gradient(to right, #8b5aa4 , #a63b92,#8b5aa4) ; */
    background-color: #F6C800;
    width: fit-content !important;
    padding: 12px 25px;
    text-transform: none;
    border-radius: 60px;
    margin: 40px auto !important;
}

.img_abt_cont {
    /* overflow: hidden; */
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 10px 0 30px 0;
    margin: 50px 0 50px 0;
}

.img_about {
    width: 90% !important;
    position: relative;
    margin: 40px 0;

}

.close_div {
    cursor: pointer;
    text-align: end;
    position: absolute;
    top: 5px;
    right: 5px;
}

.img_about img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.text_cont_abot {
    width: 60%;
    font-size: 35px !important;
    font-family: Acumin_Variable !important;
    text-align: start;
}

.text_cont_abot ul,
.text_cont_abot ul li {
    margin: 10px 0;
    width: fit-content;
    padding: 0;
    font-family: Acumin_Variable !important;
    font-size: 17px;
}

.clas_div_abt {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: -500px;
    background-color: #ffffffd1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s ease-in-out;
}

.img_about:hover .clas_div_abt {
    opacity: 1;
    top: 0;
}

.read_more_main {
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_img_tit {
    font-family: Acumin_Variable !important;
    /* font-size: 10px; */
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -40px;
    padding: 15px 20px;
    background-color: #7455ac;
    color: white;
}

.img_abt_cont .slick-slide {
    /* padding: 0 15px; */
}

.btn_read_more {
    background-color: transparent !important;
    color: black !important;
    font-size: 22px !important;
    font-family: Acumin_Variable !important;
    box-shadow: none !important;
    padding: 0 10px !important;
}

.about_img_tit h4 {
    font-family: Acumin_Variable !important;
    font-size: 30px;
    font-weight: 600;
}

.finish_edge_main {
    position: relative;
    max-width: 1300px;
    margin: 0 auto 50px auto;
    padding-bottom: 50px;
}

.image_edge_container {
    padding: 30px 0 50px 0;
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 45px; */
}

.image_edge_container .slick-track {
    display: flex;
}

.image_edge_container .slick-initialized .slick-slide {
    /* padding: 0 20px; */
}

.img_finish {
    display: block !important;
    width: 90% !important;
    margin: 0 auto;
    position: relative;
}

.img_finish img {
    width: 100%;
    display: block;
    height: 390px;
    object-fit: cover;
}

.close_div p {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.li_model {
    font-family: Acumin_Variable !important;
    font-size: 18px;
}

.model_aboutus h2 {
    font-family: Acumin_Variable !important;
    font-size: 25px;
    background-color: #8b5aa4;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 600;
}

.new_icon_text_section {
    display: flex;
    margin: 12px 0;
}

.img_finish h4 {
    min-height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    bottom: 0px; */
    font-family: Acumin_Variable !important;
    font-size: 30px;
    padding: 20px 35px;
    font-weight: 600;
    background-color: #7455ac;
    color: white;
    text-align: center;
    /* width: 100%; */
}

.edge_container {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.edge_container h3 {
    font-family: Acumin_Variable !important;
    font-size: 28px;
    text-align: center;
    margin: 20px 0 30px 0;
    line-height: 40px;
}

.edge_container button {
    font-family: Acumin_Variable !important;
    text-align: center;
    font-size: 25px !important;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
    color: black;
    /* background-image: linear-gradient(to right, #8b5aa4 , #a63b92,#8b5aa4); */
    box-shadow: 0px 20px 50px gray;
    background-color: #F6C800;
    width: fit-content !important;
    padding: 10px 45px;
    text-transform: none;
    border-radius: 60px;
    margin: 10px auto 40px auto !important;
}

.edge_container button:hover {
    box-shadow: 0px 10px 20px gray;
    color: black;
    background-color: #F6C800;
}

.Globle_cont_main {
    padding: 40px 0 100px 0;
    max-width: 1300px;
    margin: 20px auto 80px auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.ourGloble_main_container {
    position: relative;
}

.Globle_cont_main .glb_div_cont {
    width: 45%;
}

.teacher_img {
    /* height: 400px; */
}

.teacher_img img {
    width: 100%;
    height: inherit;
    object-fit: contain;
}

.principal_img .slick-next:before,
.principal_img .slick-prev:before,
.teach_slider .slick-next:before,
.teach_slider .slick-prev:before {
    color: red;
    font-family: Acumin_Variable !important;
    font-size: 25px;
}

.infra_img_div {
    position: relative;
    width: 23%;
    margin: 50px 0;
}

.infra_img_div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: bottom;
}

.infra_img_div h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    bottom: -35px;
    right: 0;
    left: 0;
    position: absolute;
    font-family: Acumin_Variable !important;
    font-size: 22px;
    color: white;
    background-color: #7455ac;
    font-weight: 600;
    padding: 5px 10px;
    width: 75%;
    margin: 0 auto;
    text-align: center;
}

.infra_main_cont {
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
}

.all_infra_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 0px 100px 0;
    margin-bottom: 50px;
}

.container_extra_acti {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0px 0;
}

.extra_act_cont {
    width: 31%;
    min-height: 300px;
    height: 300px;
    padding: 20px 0;
    margin: 0 0 50px 0;
}

.extra_act_cont div {
    min-height: inherit;
    height: inherit;
}

.extra_act_cont img {
    height: inherit;
    width: 100%;
    min-height: inherit;
    object-fit: cover;
    display: block !important;
}

.extra_activities_main {
    position: relative;
    max-width: 1300px;
    margin: 0 auto 50px auto;
    padding-bottom: 30px;
}

.extra_act_cont h3 {
    font-weight: 600;
    color: white;
    background-color: #7455ac;
    text-align: center;
    font-family: Acumin_Variable !important;
    font-size: 27px;
    padding: 20px 10px;
}

.Fisofhall_main {
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
}

.fis_img_div img {
    width: 100%;
}

.fall_main_cont {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.fis_hall_cont {
    width: 47%;
}

.fis_hall_cont h3 {
    font-family: Acumin_Variable !important;
    font-size: 35px;
    margin: 20px 0;
}

.fis_banner_cont {
    /* height: 800px; */
    position: relative;
}

.fis_banner_cont img {
    width: 100%;
    height: inherit;
    object-fit: cover;
}

.fall_hall_title {
    position: absolute;
    bottom: 20px;
    width: 60%;
    margin: 0 auto;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    padding: 10px 10px 20px 10px;
    background-color: #21656d69;
}

.fall_hall_title h3 {
    font-family: Acumin_Variable !important;
    font-size: 30px;
    font-weight: 700;
    margin: 10px 0 20px 0;
}

.fall_hall_title h4 {
    font-family: Acumin_Variable !important;
    font-size: 25px;
}

.mennu_mobile {
    display: none;
}

.model_picture {
    width: 100%;
}

.model_picture img {
    width: 100%;
}

.img_abt_cont .slick-list,
.img_abt_cont .slick-slider,
.img_abt_cont .slick-track {
    height: 500px;
}

.about_section_con {
    position: relative;
}

.sec_icon_right {
    position: absolute;
    right: 100px;
    top: -30px;
}

.sec_icon_right img {
    width: 100%;
}

.sec_icon_left {
    position: absolute;
    left: 100px;
    top: -30px;
}

.sec_icon_left img {
    width: 100%;
}

.img_finish:hover .finish_details_data {
    top: 0px;
}

.finni_img_and_content {
    position: relative;
    overflow: hidden;
}

.finish_details_data {
    transition: all 1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    top: 520px;
    position: absolute;
    background-color: #ffffffd1;
}

.finish_details_data ul {
    padding: 30px 0 20px 0;
    width: 80%;
}

.finish_details_data ul li {
    font-family: Acumin_Variable !important;
    font-size: 17px;
    margin: 10px 0;
}

.menu_nav_a {
    text-decoration: none;
    color: inherit;
}

.error_msg {
    text-align: center;
    color: red;
    font-size: 17px !important;
    font-family: Acumin_Variable !important;
}

.image_edge_container .slick-next::before,
.image_edge_container .slick-prev::before,
.img_abt_cont .slick-next::before,
.img_abt_cont .slick-prev::before {
    color: #7455ac;
    font-size: 35px;
    font-family: Acumin_Variable !important;
}

.image_edge_container .slick-prev,
.img_abt_cont .slick-prev {
    left: -20px;
}

.image_edge_container .slick-next,
.img_abt_cont .slick-next {
    right: 0px;
}

.fis_img_div {
    height: 400px;
}

.fis_img_div img {
    width: 100%;
    object-fit: cover;
}

.principal_class iframe,
.teacher_img iframe {
    height: 400px;
}

.thank_cls_div  {
    width: 100%;
    text-align: center;
}

.thank_cls_div .mobile_thnx {
    padding: 20px 0 0 0;
    width: 100vw;
    max-width: 100vw;
    height: 70vh;
    object-fit: contain;
    margin: 0 auto;
    display: none;
}

.thank_cls_div .desktop_thnx {
    padding: 20px 0 0 0;
    width: 100vw;
    max-width: 100vw;
    height: 70vh;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}

@media screen and (max-width:1300px) {

    .principal_class iframe,
    .teacher_img iframe {
        height: 350px;
    }

    .image_edge_container .slick-prev,
    .img_abt_cont .slick-prev {
        left: 0px;
    }

    .image_edge_container .slick-next,
    .img_abt_cont .slick-next {
        right: 10px;
    }

    .bann_img_cls .text_ban_cls {
        font-size: 45px;
        line-height: 46px;
        font-family: Acumin_Variable !important;
    }

    .sec_icon_right,
    .sec_icon_left {
        width: 150px;
        top: -1%;
    }

    .sec_icon_left {
        left: 5%;
    }

    .sec_icon_right {
        right: 5%;
    }

    .about_paragraph h4 {
        font-size: 23px;
        font-family: Acumin_Variable !important;

    }

    .academic_img_titl h3 {
        font-family: Acumin_Variable !important;
        font-size: 15px !important;
        width: 75%;
    }

    .btn_academic_container {
        flex-wrap: wrap;
        max-width: 90%;
        margin: 0 auto;
    }

    .btn_academic_container button {
        font-family: Acumin_Variable !important;
        font-size: 30px;
        width: 20%;
    }

    .tit_continer {
        width: 90%;
        margin: 0 auto;
    }

    .aca_main_container {
        max-width: 90%;
        margin: 0 auto;
    }

    .edge_container h3 {
        font-family: Acumin_Variable !important;
        font-size: 23px;
    }
}


@media screen and (max-width:1050px) {
    .academ_img {
        height: 150px;
    }

    .academ_img img {
        height: inherit;
        width: 100%;
        object-fit: cover;
    }

    .principal_class iframe,
    .teacher_img iframe {
        height: 300px;
    }

    .image_edge_container .slick-next::before,
    .image_edge_container .slick-prev::before,
    .img_abt_cont .slick-next::before,
    .img_abt_cont .slick-prev::before {
        font-size: 28px;
        font-family: Acumin_Variable !important;
    }

    .image_edge_container .slick-prev,
    .img_abt_cont .slick-prev {
        left: 0px;
        z-index: 99;
    }

    .image_edge_container .slick-next,
    .img_abt_cont .slick-next {
        right: 0px;
        z-index: 99;
    }

    .bann_img_cls .text_ban_cls {
        left: 0;
        right: 0;
        width: fit-content;
        margin: 0 auto;
    }

    .social_media_container {
        top: 170px;
    }

    .form_div {
        position: relative;
        margin: -20px auto 70px auto;
        right: 0;
        left: 0;
        top: -50px;
        box-shadow: 0px 0px 70px gray;
    }
}

@media screen and (max-width: 900px) {
    .infra_img_div img {
        height: 250px;
    }

    .principal_class iframe,
    .teacher_img iframe {
        height: 250px;
    }

    .sec_icon_right,
    .sec_icon_left {
        width: 100px;
        top: 0%;
    }

    .sec_icon_left {
        left: 4%;
    }

    .sec_icon_right {
        right: 4%;
    }

    .img_abt_cont {
        margin: 20px 0px;
    }

    .container_phon {
        padding: 5px 20px;
        right: -120px;
    }

    .phon_img {
        width: 35px;
        height: 35px;
    }

    .container_phon h6 a {
        font-size: 22px;
        font-family: Acumin_Variable !important;

    }

    .logo_container .MuiBox-root {
        padding: 15px 20px;
    }

    .about_img_tit {
        min-height: 50px;
    }

    .about_img_tit h4 {
        font-family: Acumin_Variable !important;
        font-size: 20px;
    }

    .bann_img_cls .banner_img {
        /* height: 600px; */
    }

    .btn_achore {
        font-family: Acumin_Variable !important;
        font-size: 17px;
    }

    .banner_about_container {
        height: 400px;
    }

    .banner_about_container img {
        object-position: center;
    }

    .btn_academic_container button {
        font-family: Acumin_Variable !important;
        font-size: 25px;
    }

    .tit_continer h2 {
        font-family: Acumin_Variable !important;
        font-size: 25px !important;
    }

    .fist_cont,
    .sec_cont {
        width: 90%;
    }

    .logo_class {
        width: auto !important;
    }

    .form_div {
        position: relative;
        margin: -20px auto 70px auto;
        right: 0;
        left: 0;
        top: -50px;
        box-shadow: 0px 0px 70px gray;
    }

    .mennu_mobile {
        display: block;
    }

    .mennu_mobile div {
        padding: 0 !important;
    }

    .container_phon {
        /* display: none; */
    }

    .aca_main_container {
        flex-wrap: wrap;
    }

    .academic_img_cont {
        width: 46%;
    }

    .btn_academic_container {
        flex-wrap: wrap;
    }

    .btn_academic_container button {
        width: auto;
        min-width: 25%;
    }

    .container_extra_acti,
    .fist_cont {
        flex-wrap: wrap;
    }

    .extra_act_cont {
        width: 30%;
        min-height: 150px;
    }

    .infra_img_div h4,
    .extra_act_cont h3 {
        font-family: Acumin_Variable !important;
        font-size: 18px;
    }

    .infra_img_div {
        width: 46%;
        margin: 40px 0;
    }

    .about_paragraph h4 {
        font-family: Acumin_Variable !important;
        font-size: 20px;
    }

    .about_paragraph {
        padding: 0 30px;
    }

    .img_abt_cont {
        flex-wrap: wrap;

    }

    .img_about {
        width: 90% !important;
        margin: 60px auto;
    }

    .image_edge_container {
        width: 90%;
        margin: 0 auto;
    }

    .img_finish {
        width: 45%;
    }

    .img_finish h4 {
        font-family: Acumin_Variable !important;
        font-size: 20px;
        padding: 15px 15px;
    }

    .edge_container h3 {
        font-family: Acumin_Variable !important;
        font-size: 22px;
        line-height: 30px;
    }

    .fall_hall_title h3 {
        font-family: Acumin_Variable !important;
        font-size: 22px;
    }

    .fall_hall_title h4 {
        font-family: Acumin_Variable !important;
        font-size: 18px;
    }

    .edge_container,
    .fall_main_cont,
    .container_extra_acti,
    .Globle_cont_main,
    .all_infra_div {
        max-width: 90%;
        margin: 0 auto;
    }

    .container_extra_acti,
    .fist_cont {
        padding: 30px 0 0px 0;
    }

    .fall_main_cont {
        padding: 50px 0;
    }

    .Globle_cont_main {
        margin: 50px auto;
        padding: 50px 0;
    }

    .all_infra_div {
        padding: 0 0 70px 0;
    }


    .social_img_div {
        margin: 10px 0;
    }

    .bann_img_cls .text_ban_cls {
        padding: 10px 20px;
        /* background-color: rgba(255, 255, 255, 0.144); */
        margin: 0 auto;
        width: fit-content;
        left: 0px;
        right: 0;
        top: 0%;
        font-size: 37px;
        font-family: Acumin_Variable !important;
        line-height: 37px;
    }
}

@media screen and (max-width:768px) {
.thank_cls_div .mobile_thnx {
    display: block;
}
.thank_cls_div .desktop_thnx {
    display: none;
}
    .infra_img_div img {
        height: 200px;
    }

    .academ_img {
        height: 180px;
    }

    .principal_class iframe,
    .teacher_img iframe {
        height: 300px;
    }

    .fis_banner_cont .img_last_banner {
        height: 400px;
    }

    .fist_cont {
        gap: 10px;
    }

    .logo1,
    .logo2,
    .logo3 {
        width: 45%;
    }

    .footer_class {
        gap: 20px;
    }

    .sec_cont h4 {
        font-size: 20px;
        font-family: Acumin_Variable !important;
    }

    .tit_continer h2 {
        padding: 10px 20px;
        font-size: 20px !important;
        font-family: Acumin_Variable !important;
    }

    .tit_continer {
        width: 70%;
        margin: 0 auto;
    }

    .sec_icon_right,
    .sec_icon_left {
        width: 90px;
        top: 0%;
    }

    .sec_icon_left {
        left: 3%;
    }

    .sec_icon_right {
        right: 3%;
    }

    .bann_img_cls .text_ban_cls {
        top: 0%;
        right: 0;
        font-size: 35px;
        line-height: 35px;
        font-family: Acumin_Variable !important;
    }

    .logo_container .MuiBox-root {
        gap: 25px;
    }

    .phone_class {
        gap: 10px;
    }

    .mennu_mobile svg {
        height: 30px;
        width: 30px;
    }

    .logo_class,
    .phone_class {
        width: 50% !important;
        display: flex;
    }

    .logo_class a,
    .new_logo a {
        width: auto !important;
        height: 45px !important;
        min-height: 45px !important;
        max-height: 45px !important;
    }

    .academic_img_titl h3 {
        font-family: Acumin_Variable !important;
        font-size: 20px !important;
    }

    .fall_main_cont {
        flex-wrap: wrap;
        gap: 40px;
    }

    .fis_hall_cont {
        width: 100%;
    }

    .extra_act_cont img {
        height: inherit;
    }

    .extra_act_cont div {
        display: block;
        height: 150px;
    }

    .container_extra_acti {
        justify-content: center;
        gap: 40px;
        padding: 30px 0;
    }

    .extra_act_cont {
        width: 45%;
        margin: 0 0 0px 0;
        padding: 0;
    }

    .img_finish h4 {
        min-height: 50px;
    }

    .Globle_cont_main {
        flex-wrap: wrap;
        gap: 70px;
    }

    .Globle_cont_main .glb_div_cont {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {

    .logo_class,
    .new_logo {
        height: auto;
    }

    .logo_container .MuiBox-root {
        padding: 10px 5px 10px 15px !important;
    }

    .academ_img {
        height: 250px;
    }

    .finish_details_data ul {
        padding: 0 0 20px 0;
        width: 80%;
    }

    .finish_details_data ul li {
        font-family: Acumin_Variable !important;
        font-size: 14px;
    }

    .finish_edge_main {
        padding-bottom: 30px;
    }

    .fall_hall_title {
        width: 70%;
    }

    .fall_hall_title h3 {
        font-size: 20px;
        font-family: Acumin_Variable !important;
    }

    .fall_hall_title h4 {
        font-size: 15px;
        font-family: Acumin_Variable !important;
    }

    .fis_hall_cont h3 {
        font-size: 25px;
        font-family: Acumin_Variable !important;
    }

    .sec_icon_right,
    .sec_icon_left {
        display: none;
        width: 70px;
        top: 0%;
    }

    .sec_icon_left {
        left: 3%;
    }

    .sec_icon_right {
        right: 3%;
    }

    .container_phon {
        padding: 10px;
        gap: 10px;
        align-items: normal;
        right: -80px;
    }

    .phon_img {
        width: 25px;
        height: 25px;
    }

    .container_phon h6,
    .container_phon h6 a {
        font-family: Acumin_Variable !important;
        font-size: 15px;
    }

    .btn_academic_container button {
        min-width: 35%;
        padding: 15px 5px !important;
    }

    .academic_img_cont {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width:500px) {


    .img_abt_cont .slick-list,
    .img_abt_cont .slick-slider,
    .img_abt_cont .slick-track {
        height: auto;
    }

    .infra_img_div h4 {
        font-size: 15px;
        min-height: 50px;
        font-family: Acumin_Variable !important;
    }

    .infra_img_div img {
        height: 150px;
    }

    .fis_img_div,
    .img_finish img,
    .img_about img {
        height: 300px;
    }

    .fis_img_div img {
        height: inherit;
    }

    .fis_banner_cont .img_last_banner {
        height: 350px;
    }

    .teacher_img {
        height: auto;
    }

    .principal_class video,
    .teacher_img video {
        height: 300px;
    }

    .finish_details_data ul {
        width: 85%;
        margin: 10px 0;
    }

    .finish_details_data ul li {
        font-size: 11px;
        margin: 8px 0;
        font-family: Acumin_Variable !important;
    }

    .bann_img_cls .text_ban_cls {
        top: 0%;
        right: 0;
        font-size: 30px;
        line-height: 30px;
        font-family: Acumin_Variable !important;
    }

    .form_div {
        width: 280px;
    }

    .container_phon {
        padding: 10px;
        right: -70px;
    }

    .phon_img {
        width: 25px;
        height: 25px;
    }

    .container_phon h6,
    .container_phon h6 a {
        font-family: Acumin_Variable !important;
        font-size: 13px;
    }

    .extra_act_cont div {
        height: 250px;
    }

    .extra_act_cont {
        width: 100%;
    }
}